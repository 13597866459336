import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/mobileRechargeRecord/pageList", formData);
}

function edit(id){
  return request.get(constant.serverUrl + "/mobileRechargeRecord/edit/" + id);
}

function update(formModel){  
  return request.post(constant.serverUrl + "/mobileRechargeRecord/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function refund(id,reason){
  var formData = new FormData();
  formData.append("reason", reason);

  return request.post(constant.serverUrl + "/mobileRechargeRecord/refund/" + id, formData);
}

export default {
  pageList,edit,update,refund
}