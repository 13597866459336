<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">增值服务</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">移动充值办理台账</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <div v-show="queryFormVisible">
      <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
        <el-form-item label="编号" prop="id" class="compact-row">
          <el-input type="text" size="mini" v-model="queryModel.id"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone" class="compact-row">
          <el-input type="text" size="mini" v-model="queryModel.phone"></el-input>
        </el-form-item>
        <el-form-item label="学生姓名" prop="studentName" class="compact-row">
          <el-input type="text" size="mini" v-model="queryModel.studentName"></el-input>
        </el-form-item>
        <el-form-item label="学校/年级/班级" prop="companyId" class="compact-row">
          <el-select-tree
            size="mini"
            :props="props"
            :options="companyResult"
            v-model="queryModel.companyId"
            height="200"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="性别" prop="sex" class="compact-row">
          <el-select
            v-model="queryModel.sex"
            size="mini"
            filterable
            clearable
            placeholder="请选择"
            style="width:100px"
          >
            <el-option v-for="(item,index) in sexList" :key="index" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缴费时间" class="compact-row">
          <el-date-picker
              v-model="queryModel.payTimeRange"
              type="daterange"
              size="mini"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              placeholder="选择时间范围"
              value-format="yyyy-MM-dd"
              >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="缴费方式" prop="payStatus" class="compact-row">
          <el-select
            v-model="queryModel.payName"
            size="mini"
            filterable
            clearable
            placeholder="请选择"
            style="width:100px"
          >
          <!--支付状态（10：未支付，15：部分支付，20：支付完成，30：关闭，40：退款）-->
            <el-option value="wechat" label="微信"></el-option>
            <el-option value="alipay" label="支付宝"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缴费状态" prop="payStatus" class="compact-row">
          <el-select
            v-model="queryModel.payStatus"
            size="mini"
            filterable
            clearable
            placeholder="请选择"
            style="width:100px"
          >
          <!--支付状态（10：未支付，15：部分支付，20：支付完成，30：关闭，40：退款）-->
            <el-option value="10" label="未支付"></el-option>
            <el-option value="15" label="部分支付"></el-option>
            <el-option value="20" label="支付完成"></el-option>
            <el-option value="30" label="关闭"></el-option>
            <el-option value="40" label="退款"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充值状态" prop="rechargeStatus" class="compact-row">
          <el-select
            v-model="queryModel.rechargeStatus"
            size="mini"
            filterable
            clearable
            placeholder="请选择"
            style="width:100px"
          >
            <el-option v-for="(item,index) in statusList" :key="index" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充值时间" class="compact-row">
          <el-date-picker
              v-model="queryModel.rechargeTimeRange"
              type="daterange"
              size="mini"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              placeholder="选择时间范围"
              value-format="yyyy-MM-dd"
              >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="compact-row">
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
          >查询</el-button>&nbsp;
          <el-button
            type="info"
            size="mini"
            style="margin-left: 8px"
            @click="handleReset('queryForm')"
          >重置</el-button>&nbsp;
        </el-form-item>
      </el-form>
    </div>
    <el-row class="button-group">
        <el-button
            type="primary"
            size="mini"
            plain
            icon="el-icon-upload2"
            @click="toggleQueryForm()"
        >{{queryFormVisible ? '隐藏' : '显示'}}查询面板</el-button>
        <el-button type="success" size="mini" plain icon="el-icon-download" @click="exportXls()">批量导出</el-button>
    </el-row>
    <el-table
      ref="formTable"
      :data="tableData"
      v-loading="loading"
      :height="tableHeight"
      stripe
    >    
      <el-table-column type="expand">
        <template slot-scope="{row}">
          <el-form label-position="left" class="demo-table-expand" :label-width="'150px'">
            <el-form-item label="充值手机号">
              <span v-html="row.phone"></span>
            </el-form-item>
            <el-form-item label="缴费方式">
              <span v-html="row.payNameN"></span>
            </el-form-item>
            <el-form-item label="外部订单号">
              <span v-html="row.outOrderNo"></span>
            </el-form-item>
            <el-form-item label="缴费金额">
              <span v-html="row.payFee"></span>元
            </el-form-item>
            <el-form-item label="缴费状态">
              <span v-html="row.payStatusN"></span>
            </el-form-item>
            <el-form-item label="应充值金额">
              <span v-html="row.rechargeAmount"></span>元
            </el-form-item>
            <el-form-item label="充值状态">
              <span v-html="row.rechargeStatusN"></span>
            </el-form-item>
            <el-form-item label="充值时间">
              <span v-html="row.rechargeTime"></span>
            </el-form-item>
            <el-form-item label="备注">
              <span v-html="row.remark"></span>
            </el-form-item>
            <el-form-item label="充值凭证截图地址">
              <img v-if="row.rechargeProofUrl" :src="row.rechargeProofUrl" class="avatar" />
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="编号" width="60"></el-table-column>
      <el-table-column prop="rechargeStatusN" label="充值状态" width="100">
        <template slot-scope="{row}">
          <span v-if="row.rechargeStatus==1" style="color:green;">已充值</span>
          <span v-else>{{row.rechargeStatusN}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="payTime" label="缴费时间" width="180"></el-table-column>
      <el-table-column prop="payNameN" label="缴费方式" width="90"></el-table-column>
      <el-table-column prop="payStatusN" label="缴费状态" width="90"></el-table-column>
      <el-table-column prop="payFee" label="缴费金额(元)" width="120"></el-table-column>
      <el-table-column prop="payPersonName" label="缴费人" width="100"></el-table-column>
      <el-table-column prop="studentName" label="学生姓名" width="100"></el-table-column>
      <el-table-column prop="phone" label="充值号码" width="120"></el-table-column>
      <el-table-column prop="rechargeAmount" label="充值金额（元）" width="120"></el-table-column>
      <el-table-column prop="schoolName" label="学校" width="180"></el-table-column>
      <el-table-column prop="className" label="班级" width="180"></el-table-column>
      <el-table-column prop="idCard" label="身份证号" width="180"></el-table-column>
      <el-table-column prop="sexN" label="性别" width="80"></el-table-column>
      <el-table-column prop="outOrderNo" label="外部订单号" width="180"></el-table-column>
      <el-table-column prop="rechargeTime" label="充值时间" width="180"></el-table-column>
      <el-table-column prop="operator" label="操作人" width="180"></el-table-column>
      <el-table-column prop="remark" label="备注" width="200"></el-table-column>
      <el-table-column label="操作" fixed="right" width="200">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="handleRefund(row)" :disabled="row.payStatus!='20'">退款</el-button>
          <el-button size="mini" type="success" @click="handleEdit(row)">充值登记</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
	<mobileRechargeRecord-detail
    v-if="showModal"
    :businessKey="businessKey"
    :title="modalTitle"
    @close="onDetailModalClose"
	></mobileRechargeRecord-detail>
  </div>
</template>
<script>
import Constant from "@/constant";
import MobileRechargeRecordDetail from "./mobileRechargeRecord-detail";
import mobileRechargeRecordApi from "@/api/business/mobileRechargeRecord";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import pageUtil from "@/utils/page";

export default {
  name: "business-mobileRechargeRecord-list",
  data() {
    var self = this;

    return {
      queryModel:{
        "id": "",
        "phone": "",
        "studentName": "",
        "payTimeRange": ['',''],
        "rechargeTimeRange": ['',''],
        "companyId" : "",
        "sex": "",
        "rechargeStatus": null,
        "payStatus": null,
        "payName" : null
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      pageSizeList: [10, 20, 30],
      showModal: false,
      modalTitle: "移动话费充值记录",
      businessKey: "",
      tableHeight: 400,
      companyResult: [],
      treeData: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
      sexList:[
        { name: "男",value:"1"},
        { name: "女",value:"2"}
      ],
      statusList:[
        { name: "未充值",value:"0"},
        { name: "已充值",value:"1"}
      ],
      refundReason: "",
      queryFormVisible: true
    };
  },
  methods: {
    toggleQueryForm(){
      this.queryFormVisible = !this.queryFormVisible;

      this.$nextTick(()=>{
          this.tableHeight = pageUtil.autoAdjustHeight(this.$refs.formTable.$el);
      });
    },
    changePage(pageIndex,exportFlag) {
      var self = this;
      
      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);

      if(exportFlag!=null) {        
        formData.append("exportFlag", 1);
        formData.append("pageSize", 10000);
      }
      else{
        formData.append("pageSize", self.pageSize);
      }

      if (this.queryModel.id != null) {
        formData.append("id", this.queryModel.id);
      }

      if (this.queryModel.phone != null) {
        formData.append("phone", this.queryModel.phone);
      }

      if (this.queryModel.studentName != null) {
        formData.append("studentName", this.queryModel.studentName);
      }

      if (this.queryModel.sex != null) {
        formData.append("sex", this.queryModel.sex);
      }

      if (this.queryModel.companyId != null) {
        formData.append("companyId", this.queryModel.companyId);
      }

      if (this.queryModel.rechargeStatus != null) {
        formData.append("rechargeStatus", this.queryModel.rechargeStatus);
      }

      if (this.queryModel.payStatus != null) {
        formData.append("payStatus", this.queryModel.payStatus);
      }

      if (this.queryModel.payName != null) {
        formData.append("payName", this.queryModel.payName);
      }
      

      if (this.queryModel.payTimeRange[0] != null) {
        formData.append("payTime1", this.queryModel.payTimeRange[0]);
      }

      if (this.queryModel.payTimeRange[1] != null) {
        formData.append("payTime2", this.queryModel.payTimeRange[1]);
      }

      if (this.queryModel.rechargeTimeRange[0] != null) {
        formData.append("rechargeTime1", this.queryModel.rechargeTimeRange[0]);
      }

      if (this.queryModel.rechargeTimeRange[1] != null) {
        formData.append("rechargeTime2", this.queryModel.rechargeTimeRange[1]);
      }

      mobileRechargeRecordApi.pageList(formData).then(function(response) {
        self.loading = false;

        var jsonData = response.data;

        if(jsonData.result) {
          if(exportFlag){
            //导出
            self.$message({
              showClose: true,
              type: "success",
              message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
              dangerouslyUseHTMLString: true,
              duration: 30000
            });
          }
          else{
            //分页查看
            var page = jsonData.data;

            self.tableData = page.data;
            self.totalPages = page.totalPages;
            self.totalElements = page.recordsTotal;
          }
        }
        else{
          self.$message.warning(jsonData.message + "");
        }
      }).catch((error)=>{
        self.loading = false;
        // self.$message.error(error + "");
      });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
      
      this.$nextTick(()=>{
        this.changePage(this.pageIndex);
      });
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleRefund(record) {
      var self = this;

      self.$prompt("是否确认退款?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPlaceholder: "请输入退款理由",
          type: "warning",
          inputValidator: (value)=>{
            return value!=null && value.length>0;
          },
          inputErrorMessage: "退款理由不能为空!"
        })
        .then(({value}) => {
          self.loading = true;

          mobileRechargeRecordApi.refund(record.id,value).then(response=>{
            var jsonData = response.data;
            self.loading = false;

            if(jsonData.result){
              this.$message.success(jsonData.message + "");
              this.changePage(1);
            }
            else{
              this.$message.error(jsonData.message + "");
            }
          });
        });
    },
    handleEdit(record) {
      this.businessKey = record.id;
      this.showModal = true;
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if(refreshed){
        this.changePage(this.pageIndex);
      }
    },
    exportXls() {
      this.changePage(1,true);
    }
  },
  mounted: function() {
    var self = this;

    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;

      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });

    this.changePage(1);

    setTimeout(() => {
      console.log("offsetTop="+ self.$refs.formTable.$el.offsetTop);

      //页面高度-列表上面的高度-header高度-分页控件高度
      self.tableHeight = pageUtil.autoAdjustHeight(self.$refs.formTable.$el);
    }, 1000);
  },
  components: {
    "mobileRechargeRecord-detail": MobileRechargeRecordDetail,
    "el-select-tree": SelectTree
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}

.compact-row{
  margin-bottom: 10px !important;
}
</style>