<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    :append-to-body="true"
    style="text-align:left;"
    @close="closeDialog"
  >
    <div class="user-panel" v-loading="loading">
      <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'150px'">
        <el-form-item label="充值手机号" prop="phone">
          <span v-html="formModel.phone"></span>
        </el-form-item>
        <el-form-item label="缴费金额" prop="rechargeAmount">
          <span v-html="formModel.payFee"></span>元
        </el-form-item>
        <el-form-item label="应充值金额" prop="rechargeAmount">
          <span v-html="formModel.rechargeAmount"></span>元
        </el-form-item>
        <el-form-item label="充值时间" prop="rechargeTime">
          <el-date-picker
            v-model="formModel.rechargeTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="选择充值时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入备注"
            v-model="formModel.remark">
          </el-input>
        </el-form-item>
        <el-form-item label="充值凭证截图地址" prop="rechargeProofUrl">
          <upload-image v-model="formModel.rechargeProofUrl" sub-folder="mobileRechargeRecord" :size="128"></upload-image>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import { getToken } from "@/utils/auth"; // get token from cookie
import mobileRechargeRecordApi from "@/api/business/mobileRechargeRecord";
import UploadImage from "@/components/UploadImage";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      ruleValidate: {
        rechargeTime: [
          { required: true, message: "充值时间不能为空", trigger: "blur" },
        ]
        // ,rechargeProofUrl: [
        //   {
        //     required: true,
        //     message: "充值凭证截图地址不能为空",
        //     trigger: "blur",
        //   },
        // ]
      },
      showDialog: true,
      loading: false,
      submitting: false,
      formModel: {}
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$confirm("是否确定已充值？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            var id = self.formModel.id;
            self.formModel.rechargeStatus = 1;

            mobileRechargeRecordApi.update(self.formModel)
            .then(function (response) {
              var jsonData = response.data;

              if (jsonData.result) {
                self.$message({
                  message: "保存成功!",
                  type: "success",
                });

                self.$emit("close", true);
              } else {
                self.$message({
                  message: jsonData.message + "",
                  type: "warning",
                });

                self.$emit("close", false);
              }
            });
          });
        }
      });
    }
  },
  mounted: function () {
    var self = this;

    mobileRechargeRecordApi
      .edit(self.businessKey)
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "upload-image": UploadImage
  }
};
</script>
<style lang="scss">
.avatar{
 object-fit: cover;
 width:200px;
 height:200px;
}
</style>